import React from 'react';
import { graphql } from 'gatsby';
import '../fragments';
import { BankCharacteristics } from '../graphql-fragments/Bank';

import {
  renderBankScale,
  renderBoolean,
  renderCountries,
  renderPrice,
} from '../utils/characteristicsRenderFunctions';

import EntityPage from './EntityPage';

import * as styles from './EntityPage.module.scss';
import { ENTITY_TYPES_WITH_REVIEWS } from '../constants';
import { RawPortableText } from '../types';

export const query = graphql`
  query GetBankPageById($id: String!) {
    sanityBank(id: { eq: $id }) {
      ...Bank
    }
    sanityBankPage {
      messageForm {
        introText
        _rawWarningText(resolveReferences: { maxDepth: 6 })
        bottomNoteText
      }
    }
  }
`;

interface BankPageProps {
  data: {
    sanityBank: BankCharacteristics;
    sanityBankPage?: {
      messageForm?: {
        introText?: string;
        _rawWarningText?: RawPortableText;
        bottomNoteText?: string;
      };
    };
  };
}

const BankPage = ({ data }: BankPageProps): React.ReactElement => {
  const {
    _id,
    slug,
    hubspotInternalValue,
    logoImage,
    personImage,
    name,
    personName,
    mainVideoUrl,
    approved,
    introText,
    _rawDescription,
    price,
    countriesWithOffices,
    scale,
    hasRetailBranches,
    seo,
  } = data.sanityBank;
  const bankCharacteristics: Array<{ title: string; value: string | React.ReactElement }> = [
    { title: 'Price', value: price && renderPrice(price, styles) },
    { title: 'Countries With Offices', value: renderCountries(countriesWithOffices) },
    { title: 'Scale', value: renderBankScale(scale) },
    { title: 'Has Retail Branches', value: renderBoolean(hasRetailBranches) },
  ];

  return (
    <EntityPage
      entityType="bank"
      entityId={_id}
      slug={slug.current}
      hubspotInternalValue={hubspotInternalValue}
      isApproved={approved}
      hasResponsiblePerson={true}
      personImage={personImage}
      personName={personName}
      image={logoImage}
      topic={'bank'}
      title={name}
      text={introText}
      content={_rawDescription}
      characteristics={bankCharacteristics}
      videoUrl={mainVideoUrl}
      hasReviews={ENTITY_TYPES_WITH_REVIEWS.includes('bank')}
      seo={seo}
    />
  );
};

export default BankPage;
